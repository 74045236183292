const frontProjectsData = [
    {
        header: 'Ethno Hotels',
        lang: 'Javascript,Jquery,UIkit',
        text: `Form validations with pixel perfect design jquery and performance improvement with javascript, data display on the frontend with endpoints and google map integration were made.`,
        link:'https://www.ethnohotels.com/',
    },
    {
        header: 'Schloss Lüdersburg',
        lang: 'Javascript,Jquery,UIkit',
        text: `Form validations with pixel perfect design jquery and performance improvement with javascript, data display on the frontend with endpoints and google map integration were made.`,
        link:'https://www.schloss-luedersburg.de/',
    },
    {
        header: 'Buket Yıkama',
        lang: 'Javascript,Jquery,UIkit',
        text: `Form validations with pixel perfect design jquery and performance improvement with javascript, data display on the frontend with endpoints and google map integration were made.`,
        link:'https://www.buket.com.tr/',
    },
    {
        header: 'Akra Fit',
        lang: 'Javascript,Jquery,UIkit',
        text: `Form validations with pixel perfect design jquery and performance improvement with javascript, data display on the frontend with endpoints and google map integration were made.`,
        link:'https://www.akrafit.com/',
    },
    {
        header: '251 Soul',
        lang: 'Javascript,Jquery,UIkit',
        text: `Form validations with pixel perfect design jquery and performance improvement with javascript, data display on the frontend with endpoints and google map integration were made.`,
        link:'https://www.251soul.com/',
    },
    {
        header: 'Pablito Bistro',
        lang: 'Javascript,Jquery,UIkit',
        text: `Form validations with pixel perfect design jquery and performance improvement with javascript, data display on the frontend with endpoints and google map integration were made.`,
        link:'https://www.pablitobistro.com/en/',
    },
    {
        header: 'Doktar',
        lang: 'Javascript,Jquery,UIkit',
        text: `Form validations with pixel perfect design jquery and performance improvement with javascript, data display on the frontend with endpoints and google map integration were made.`,
        link:'https://www.doktar.com/en/',
    },
    {
        header: 'Stork Camper',
        lang: 'Javascript,Jquery,UIkit',
        text: `Form validations with pixel perfect design jquery and performance improvement with javascript, data display on the frontend with endpoints and google map integration were made.`,
        link:'https://storkcamper.com/',
    },
    {
        header: 'Nef',
        lang: 'Javascript,Jquery,UIkit',
        text: `Form validations with pixel perfect design jquery and performance improvement with javascript, data display on the frontend with endpoints and google map integration were made.`,
        link:'https://www.nef.com.tr/',
    },
    {
        header: 'Akra Hotels',
        lang: 'Javascript,Jquery,UIkit',
        text: `Form validations with pixel perfect design jquery and performance improvement with javascript, data display on the frontend with endpoints and google map integration were made.`,
        link:'https://akrahotels.com/',
    },
    {
        header: 'Akra QR',
        lang: 'Javascript,Jquery,UIkit',
        text: `Form validations with pixel perfect design jquery and performance improvement with javascript, data display on the frontend with endpoints and google map integration were made.`,
        link:'https://qr.akrahotels.com/',
    },
    {
        header: 'BHM Group',
        lang: 'Javascript,Jquery,UIkit',
        text: `Form validations with pixel perfect design jquery and performance improvement with javascript, data display on the frontend with endpoints and google map integration were made.`,
        link:'https://www.bhmotelcilik.com/',
    },
    {
        header: 'Global Ports Holding',
        lang: 'Javascript,Jquery,UIkit',
        text: `Form validations with pixel perfect design jquery and performance improvement with javascript, data display on the frontend with endpoints and google map integration were made.`,
        link:'https://www.globalportsholding.com/',
    },
    {
        header: 'NG Hotels',
        lang: 'Javascript,Jquery,UIkit',
        text: `Form validations with pixel perfect design jquery and performance improvement with javascript, data display on the frontend with endpoints and google map integration were made.`,
        link:'https://www.nghotels.com.tr/tr/',
    },
    {
        header: 'Sirene Hotels',
        lang: 'Javascript,Jquery,UIkit',
        text: `Form validations with pixel perfect design jquery and performance improvement with javascript, data display on the frontend with endpoints and google map integration were made.`,
        link:'https://www.sirene.com.tr/',
    },
    {
        header: 'Doxa',
        lang: 'Javascript,Jquery,UIkit',
        text: `Form validations with pixel perfect design jquery and performance improvement with javascript, data display on the frontend with endpoints and google map integration were made.`,
        link:'https://www.doxa.com.tr/',
    },
    {
        header: 'Regnum Hotels',
        lang: 'Javascript,Jquery,UIkit',
        text: `Form validations with pixel perfect design jquery and performance improvement with javascript, data display on the frontend with endpoints and google map integration were made.`,
        link:'https://www.regnumhotels.com/tr/',
    },
    {
        header: 'Dinamis',
        lang: 'Javascript,Jquery,UIkit',
        text: `Form validations with pixel perfect design jquery and performance improvement with javascript, data display on the frontend with endpoints and google map integration were made.`,
        link:'https://www.dinamis.com.tr/',
    },
    {
        header: 'Calista Luxury Resort',
        lang: 'Javascript,Jquery,UIkit',
        text: `Form validations with pixel perfect design jquery and performance improvement with javascript, connecting coordinates with endpoint and displaying multiple maps.`,
        link:'https://calista.com.tr/',
    },
    {
        header: 'Matadoor',
        lang: 'Javascript,Jquery,UIkit',
        text: `Form validations with pixel perfect design jquery and performance improvement with javascript, connecting coordinates with endpoint and displaying multiple maps.`,
        link:'https://www.matador.com.tr/',
    },
    {
        header: 'Super Film',
        lang: 'Javascript,Jquery,UIkit',
        text: `Form validations with pixel perfect design jquery and performance improvement and calculation modules with javascript were made.`,
        link:'https://www.superfilm.com/',
    },
    {
        header: 'IQ Aluminyum',
        lang: 'Javascript,Jquery,UIkit',
        text: `Form validations with pixel perfect design jquery and performance improvement and calculation modules with javascript were made.`,
        link:'https://www.iqaluminyum.com/',
    },
    {
        header: 'Poligon Construction',
        lang: 'Javascript,Jquery,UIkit',
        text: `Form validations with pixel perfect design jquery and performance improvement with javascript.`,
        link:'http://www.poligoninsaat.com.tr/',
    },
    {
        header: 'Construction Portfolio',
        lang: 'React,SASS',
        text: `The website where the person's work experiences, projects and educational information are displayed.`,
        link:'https://github.com/melihdizdar/construction-portfolio',
    },
    {
        header: 'Restaurant Portfolio',
        lang: 'React,SASS',
        text: `The website where the information of restaurants in the type of shops is published.`,
        link:'https://github.com/melihdizdar/restaurant-portfolio',
    },
    {
        header: 'Your Tastehouse',
        lang: 'React,CSS',
        text: `The website where the company showcases its products.`,
        link:'https://github.com/melihdizdar/your-tastehouse',
    },
    {
        header: 'CV Portfoilo',
        lang: 'React,CSS',
        text: `The website where the person's work experiences, projects and educational information are displayed.`,
        link:'https://github.com/melihdizdar/cv-portfoilo-project',
    },
    {
        header: '2E Shoes Textile',
        lang: 'Html,CSS,Dreamviewer',
        text: `The project in which shoe textile products are previewed.`,
        link:'https://github.com/melihdizdar/2e-shoes-textile',
    },
]

export default frontProjectsData;